import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { LOGIN_FACEBOOK } from '../../../../../../graphql/Auth';

import facebookIcon from '../../../../../../assets/img/facebook-icon.svg';

import styles from './SocialSignUp.module.css';

const FacebookButton = props => {
  const {
    translations,
    language,
    refererUserId,
    facebookLoginSuccessful
  } = props;

  const [error, setError] = useState(false);

  const [login, { loading }] = useMutation(LOGIN_FACEBOOK, {
    onCompleted: ({ loginFacebook: data }) => {
      if (data && data.errors && data.errors.length >= 1) {
        setError(true);
      } else if (data && data.loginToken) {
        setError(false);
        facebookLoginSuccessful(data.loginToken);
      }
    },
    onError: () => setError(true)
  });

  const handleFacebookCallback = response => {
    if (response && response.accessToken) {
      login({
        variables: {
          accessToken: response.accessToken,
          language: language || 'NL',
          ...(refererUserId ? { refererUserId } : {})
        }
      });
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <FacebookLogin
        appId="1458049280965946"
        fields="email"
        callback={handleFacebookCallback}
        render={renderProps => (
          <div
            role="presentation"
            onClick={renderProps.onClick}
            className={`${styles.socialButton} ${
              loading ? styles.loading : ''
            }`}
          >
            <img
              src={facebookIcon}
              className={styles.facebookIcon}
              alt="Facebook icon"
            />
            <span>{translations.CONTINUE_WITH_FACEBOOK}</span>
          </div>
        )}
      />
      {error ? (
        <div className={styles.error}>
          {translations.REGISTER_ERROR_FACEBOOK}
        </div>
      ) : null}
    </div>
  );
};

export default FacebookButton;
