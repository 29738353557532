import React from 'react';
import qs from 'query-string';

import styles from './SignUpExplanation.module.css';

const SignUpExplanationOrganisationReferral = props => {
  const { location, translations, signUpCompleted } = props;

  const query = qs.parse(location.search);

  return (
    <div className={styles.container}>
      <div className={styles.desktopContainer}>
        <div className={styles.titleLabel}>
          {translations.SUPPORT_YOUR_ORGANISATION}
        </div>
        <h1 className={styles.title}>
          {translations.JOIN_AND_SAVE_WITH_ORGANISATION}
        </h1>
        <p className={styles.text}>{translations.FUNDRAISING_MADE_EASY}</p>
        <p className={styles.text}>{translations.FOLLOW_STEPS_START_TODAY}</p>
        <p>
          <div className={styles.subTitle}>
            {translations.STEP_1_TITLE_ORGANISATION}
          </div>
          <div className={styles.text}>
            {translations.STEP_1_DESCRIPTION_ORGANISATION}
            <span className={styles.textItalic}>
              {' '}
              {translations.STEP_1_DESCRIPTION_ORGANISATION_ITALIC}
            </span>
          </div>
        </p>
        <p>
          <div className={styles.subTitle}>
            {translations.STEP_2_TITLE_ORGANISATION}
          </div>
          <div className={styles.text}>
            {translations.STEP_2_DESCRIPTION_ORGANISATION}
            <span className={styles.textItalic}>
              {' '}
              {translations.STEP_2_DESCRIPTION_ORGANISATION_ITALIC}
            </span>
          </div>
        </p>
        <p>
          <div className={styles.subTitle}>
            {translations.STEP_3_TITLE_ORGANISATION}
          </div>
          <div className={styles.text}>
            {translations.STEP_3_DESCRIPTION_ORGANISATION}
          </div>
        </p>
        <p>
          <div className={styles.text}>
            {translations.WHAT_ARE_YOU_WAITING_FOR}
          </div>
          <div className={styles.text}>
            {translations.START_AND_SAVE_FOR_ORGANISATION}
          </div>
        </p>
      </div>
      <div
        className={`${styles.mobileContainer} ${
          !(
            query &&
            query.step &&
            (query.step === 'completed' ||
              query.step === 'emailSignUp' ||
              signUpCompleted)
          )
            ? styles.showMobileContainer
            : ''
        }`}
      >
        <h1 className={styles.title}>
          {translations.JOIN_AND_SAVE_WITH_ORGANISATION}
        </h1>
        <p className={styles.text}>{translations.FUNDRAISING_MADE_EASY}</p>
        <p>
          <div className={styles.text}>
            {translations.STEP_3_DESCRIPTION_ORGANISATION}
          </div>
        </p>
      </div>
    </div>
  );
};

export default SignUpExplanationOrganisationReferral;
