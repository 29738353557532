import React from 'react';

import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
// import AppleButton from './AppleButton';

import styles from './SocialSignUp.module.css';

const SocialSignUp = props => {
  const {
    translations,
    language,
    refererUserId,
    addQueryParam,
    socialLoginSuccessful
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translations.BECOME_MEMBER}</div>
      <div className={styles.buttonsContainer}>
        <FacebookButton
          translations={translations}
          language={language}
          refererUserId={refererUserId}
          facebookLoginSuccessful={socialLoginSuccessful}
        />
        <GoogleButton
          translations={translations}
          language={language}
          refererUserId={refererUserId}
          googleLoginSuccessful={socialLoginSuccessful}
        />
        {/* <AppleButton
          translations={translations}
          language={language}
          refererUserId={refererUserId}
          appleLoginSuccessful={socialLoginSuccessful}
        /> */}
      </div>
      <div
        className={styles.proceedWithEmail}
        role="presentation"
        onClick={() => {
          addQueryParam('step', 'emailSignUp');
        }}
      >
        {translations.REGISTER_WITH}{' '}
        <span className={styles.proceedWithEmailBold}>
          {translations.EMAIL_ADDRESS}
        </span>
      </div>
    </div>
  );
};

export default SocialSignUp;
