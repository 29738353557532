import gql from 'graphql-tag';

export const GET_APP_CONFIGURATION = gql`
  query GetAppConfiguration {
    getAppConfiguration {
      legal {
        privacyPolicy {
          EN {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
          NL {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
          FR {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
        }
        summary {
          NL {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
          FR {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
          EN {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
          DE {
            markdownContent {
              title
              content {
                type
                name
                content
              }
            }
          }
        }
      }
      profileOptions {
        genders {
          label
          value
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      loginToken
      errors {
        message
        field
        language
      }
    }
  }
`;

export const LOGIN_FACEBOOK = gql`
  mutation LoginFacebook(
    $accessToken: String!
    $language: String!
    $trackingParamsString: String
    $deviceId: String
    $refererUserId: String
  ) {
    loginFacebook(
      input: {
        token: $accessToken
        language: $language
        trackingParamsString: $trackingParamsString
        deviceId: $deviceId
        refererUserId: $refererUserId
      }
    ) {
      loginToken
      errors {
        message
        field
        language
      }
    }
  }
`;

export const LOGIN_GOOGLE = gql`
  mutation LoginGoogle(
    $idToken: String!
    $language: String!
    $trackingParamsString: String
    $deviceId: String
    $refererUserId: String
  ) {
    loginGoogle(
      input: {
        token: $idToken
        language: $language
        trackingParamsString: $trackingParamsString
        deviceId: $deviceId
        refererUserId: $refererUserId
      }
    ) {
      loginToken
      errors {
        message
        field
        language
      }
    }
  }
`;

export const LOGIN_APPLE = gql`
  mutation LoginApple(
    $identityToken: String!
    $language: String!
    $nonce: String
    $applicationId: String!
    $authorizationCode: String!
    $trackingParamsString: String
    $deviceId: String
    $refererUserId: String
  ) {
    loginApple(
      input: {
        token: $identityToken
        language: $language
        nonce: $nonce
        applicationId: $applicationId
        authorizationCode: $authorizationCode
        trackingParamsString: $trackingParamsString
        deviceId: $deviceId
        refererUserId: $refererUserId
      }
    ) {
      loginToken
      errors {
        message
        field
        language
      }
    }
  }
`;

export const GET_APP_CONFIGURATION_REGISTER = gql`
  query GetAppConfiguration {
    getAppConfiguration {
      profileOptions {
        countries {
          label
          value
        }
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Register(
    $email: String!
    $password: String!
    $language: String!
    $refererUserId: String
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        language: $language
        refererUserId: $refererUserId
      }
    ) {
      done
      errors {
        message
        field
        language
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser(
    $gender: Gender
    $dateOfBirth: DateTime
    $country: Country
    $city: String
    $civilStatus: CivilStatus
    $newHasChildren: HasChildren
    $newOccupation: OccupationDataInput
    $ignoreProfileIncompleteness: Boolean
    $language: Language
    $province: String
    $postCode: String
    $place: String
    $livingArrangement: LivingArrangement
    $surroundings: Surroundings
    $degree: Degree
    $nickname: String
  ) {
    updateUser(
      input: {
        gender: $gender
        dateOfBirth: $dateOfBirth
        country: $country
        city: $city
        civilStatus: $civilStatus
        newHasChildren: $newHasChildren
        newOccupation: $newOccupation
        ignoreProfileIncompleteness: $ignoreProfileIncompleteness
        language: $language
        province: $province
        postCode: $postCode
        place: $place
        livingArrangement: $livingArrangement
        surroundings: $surroundings
        degree: $degree
        nickname: $nickname
      }
    ) {
      user {
        id
        gender
        dateOfBirth
        country
        city
        civilStatus
        newHasChildren
        email
        newOccupation {
          mainActivity
          functionGroup
          sector
          kindOfSelfEmployed
          degree
          studyArea
        }
        profileCompleteness
        language
        province
        postCode
        place
        livingArrangement
        surroundings
        degree
        nickname
        parent
      }
      errors {
        message
        field
        language
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(input: { password: $password, token: $token }) {
      done
    }
  }
`;

export const GET_AUTHENTICATED_CLIENT = gql`
  query GetAuthenticatedClient($token: String!) {
    getAuthenticatedClient(input: { token: $token }) {
      client
      errors {
        field
        message
        language
      }
    }
  }
`;
