import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import qs from 'query-string';

import { GET_APP_CONFIGURATION_REGISTER } from '../../../graphql/Auth';

import AppBar from './AppBar/AppBar';
import SignUpExplanationOrganisationReferral from './SignUpExplanation/SignUpExplanationOrganisationReferral';
import SignUpExplanationUserReferral from './SignUpExplanation/SignUpExplanationUserReferral';
import SignUpForm from './SignUpForm/SignUpForm';

import DutchStrings from '../../../strings/nl';
import FrenchStrings from '../../../strings/fr';
import EnglishStrings from '../../../strings/en';
import GermanStrings from '../../../strings/de';

import styles from './RegistrationPage.module.css';

const languages = {
  English: EnglishStrings,
  Dutch: DutchStrings,
  French: FrenchStrings,
  German: GermanStrings
};

const RegistrationPage = props => {
  const { location, history } = props;

  let isOrganisationUserRegister = false;
  if (
    location &&
    location.pathname &&
    location.pathname === '/register/organisation'
  ) {
    isOrganisationUserRegister = true;
  }

  const [fields, setFields] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    gender: '',
    civil_status: '',
    has_children: '',
    country: '',
    city: '',
    date_of_birth: '',
    language: 'NL'
  });
  const [translations, setTranslations] = useState(languages.Dutch);
  const [signUpCompleted, setSignUpCompleted] = useState(false); // After completing email/social sign up then persist completed state

  useEffect(() => {
    const query = qs.parse(location.search);
    let transactionID;
    let referalID;
    const fieldValuesToSet = {};

    if (query && query.transaction_id) {
      transactionID = query.transaction_id;
    }
    if (query && query.referal) {
      referalID = query.referal;
    }
    if (query && query.lang) {
      if (query.lang === 'FR') {
        fieldValuesToSet.language = 'FR';
      }
    }
    // Conversion tracking with Tune platform (Server Postback Tracking) for client 'MVF'
    if (transactionID) {
      let fetchURL;
      if (query && query.lang && query.lang === 'FR') {
        fetchURL = `https://mvfglobal.go2cloud.org/aff_lsr?offer_id=111&transaction_id=${transactionID}`;
      } else {
        fetchURL = `https://mvfglobal.go2cloud.org/aff_lsr?offer_id=156&transaction_id=${transactionID}`;
      }
      fetch(fetchURL);
    }
    if (referalID) {
      fieldValuesToSet.refererUserId = referalID;
    }

    if (fieldValuesToSet && Object.values(fieldValuesToSet).length) {
      setFields({ ...fields, ...fieldValuesToSet });
    }
  }, []);

  useEffect(
    () => {
      if (fields.language) {
        switch (fields.language) {
          case 'NL':
            setTranslations(languages.Dutch);
            break;
          case 'FR':
            setTranslations(languages.French);
            break;
          case 'EN':
            setTranslations(languages.English);
            break;
          case 'DE':
            setTranslations(languages.German);
            break;
          default:
            setTranslations(languages.Dutch);
        }
      }
    },
    [fields.language]
  );

  const { data: { getAppConfiguration } = {} } = useQuery(
    GET_APP_CONFIGURATION_REGISTER,
    {
      fetchPolicy: 'network-only'
    }
  );

  const setFieldValue = (name, value) =>
    setFields({ ...fields, [name]: value });

  if (!getAppConfiguration) {
    return null;
  }

  return (
    <div className={styles.registrationWrapper}>
      <AppBar fields={fields} setFieldValue={setFieldValue} />
      <div className={styles.contentContainer}>
        <div className={styles.left}>
          {isOrganisationUserRegister ? (
            <SignUpExplanationOrganisationReferral
              location={location}
              translations={translations}
              signUpCompleted={signUpCompleted}
            />
          ) : (
            <SignUpExplanationUserReferral
              location={location}
              translations={translations}
              signUpCompleted={signUpCompleted}
            />
          )}
        </div>
        <div className={styles.right}>
          <SignUpForm
            location={location}
            history={history}
            fields={fields}
            setFields={setFields}
            signUpCompleted={signUpCompleted}
            setSignUpCompleted={setSignUpCompleted}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
